import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export default el => {

    const dom = $(el);
    const boxes = dom.find('[data-text]');

    let triggers = [];

    const destroyScrollTriggers = () => {
        triggers.forEach(trigger => trigger.kill());
        triggers = [];
    };

    const destroy = () => {
        destroyScrollTriggers();
    };

    const getMediaElement = node => {
        const previousElement = node.parentNode.previousElementSibling;
        return previousElement.nodeName === 'FIGURE' ? previousElement : null;
    };

    const init = () => {
        const bottomTrigger = ScrollTrigger.create({
            trigger: el,
            start: 'top top',
            end: 'bottom bottom',
            onEnter() {
                dom.addClass('js-at-top');
            },
            onEnterBack() {
                dom.removeClass('js-at-bottom');
            },
            onLeave() {
                dom.addClass('js-at-bottom');
            },
            onLeaveBack() {
                dom.removeClass('js-at-top');
            }
        });

        triggers.push(bottomTrigger);

        boxes.each(box => {
            const trigger = ScrollTrigger.create({
                trigger: box,
                start: 'top bottom-=100px',
                end: 'top top-=100px',
                onEnter() {
                    console.log('onEnter');
                    const media = getMediaElement(box);
                    if (media) {
                        const video = media.querySelector('video');
                        if (video) {
                            video.instance.play();
                        }
                        gsap.killTweensOf(media);
                        gsap.to(media, { duration: 1.5, autoAlpha: 1, ease: 'power2.inOut' });
                    }
                },
                onLeaveBack() {
                    console.log('onLeaveBack');
                    const media = getMediaElement(box);
                    if (media && media !== el.firstElementChild) {
                        const video = media.querySelector('video');
                        if (video) {
                            video.instance.pause();
                        }
                        gsap.killTweensOf(media);
                        gsap.to(media, { duration: 1.5, autoAlpha: 0, ease: 'power2.inOut' });
                    }
                }
            });
            triggers.push(trigger);
        });
    };

    return {
        init,
        destroy
    };

};
