import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const SELECTED_CLASSES = ['bg-green','border-green','text-black','hover:bg-white'];
    const UNSELECTED_CLASSES = ['bg-white','border-black','text-black','hover:bg-black','hover:text-white'];
    
    const $el = $(el);

    const show = $target => {
        const id = $target.attr('href');
        
        if (id !== undefined && id !== '') {
            if (id === '#all') {
                $el.find('[data-category-wrap]').css({ display: 'block' });
            } else {
                $el.find('[data-category-wrap]').css({ display: 'none' });
                $(id).css({ display: 'block' });
            }
            
            $el.find('[data-category-nav] a').removeClass(...SELECTED_CLASSES).addClass(...UNSELECTED_CLASSES);
            $target.removeClass(...UNSELECTED_CLASSES).addClass(...SELECTED_CLASSES);
        }
    };
    
    
    const init = () => {
        $el.on('click', '[data-category-nav] a', e => {
            e.preventDefault();
            show($(e.triggerTarget));
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
