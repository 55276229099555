import './lib/polyfills';
import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './lib/ViewportHeight';
import ActionNumberHandler from './lib/ActionNumberHandler';

gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollTrigger);

const fixWysiwyg = () => {
    $('.wysiwyg a > .btn-action').each(el => {
        const $el = $(el);
        $el.parent().addClass('primary-btn');
        $el.parent().text($el.text());
        $el.removeClass('btn-action');
    });
    
    $('.wysiwyg .btn-action > a').each(el => {
        const $el = $(el);
        $el.addClass('primary-btn');
        $el.parent().removeClass('btn-action');
    });
};

const init = () => {
    ActionNumberHandler.checkActionNumber();
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    ViewportHeight.init();
    lazySizes.init();
    fixWysiwyg();
};

require('doc-ready')(init);
