import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el);
    const $body = $('body');
    const $bar = $el.find('[data-menu-bar]');

    const $menu = $el.find('#menu');
    const $menuTriggers = $el.find('[data-menu-toggle]');
    const $menuInner = $menu.find('[data-menu-inner]');

    const $search = $el.find('#search');
    const $searchTriggers = $el.find('[data-search-toggle]');
    const $searchInner = $search.find('[data-search-inner]');
    const $searchField = $search.find('[data-search-field]').get(0);

    let searchOpen = false;
    let menuOpen = false;
    let focusedElement = null;
    let breakpoint = Viewport.breakpoint.name;

    let isTransparent = false;
    let isHidden = false;
    let hiddenThreshold = 50;
    let scrolledThreshold = 80;
    let currentScrollTop = Viewport.scrollTop;
    let enableAnimationsTimeout = -1;

    const disableAnimations = () => {
        $body.addClass('disable-animations');
    };

    const enableAnimations = () => {
        clearTimeout(enableAnimationsTimeout);
        enableAnimationsTimeout = setTimeout(() => {
            $body.removeClass('disable-animations');
        }, 100);
    };

    const openMenu = () => {
        focusedElement = document.activeElement;
        $menu.css({ height: '0%' });
        $menuInner.css({ opacity: 0 });

        gsap.to($menu.nodes, { duration: 0.4, ease: 'power3.out', height: '100%' });
        gsap.to($menuInner.nodes, { duration: 0.5, delay: 0.4, opacity: 1 });
    };

    const closeMenu = () => {
        gsap.to($menuInner.nodes, { duration: 0.1, delay: 0, opacity: 0 });
        gsap.to($menu.nodes, { duration: 0.3, delay: 0.1, ease: 'power1.out', height: '0%', onComplete: () => {
            focusedElement.focus();
            focusedElement = null;
        }});
    };

    const toggleMenu = () => {
        if (menuOpen) {
            closeMenu();
        } else {
            openMenu();
        }

        menuOpen = !menuOpen;
    };

    const openSearch = () => {
        focusedElement = document.activeElement;
        $search.css({ height: '0%' });
        $searchInner.css({ opacity: 0 });

        gsap.to($search.nodes, { duration: 0.4, ease: 'power3.out', height: '100%' });
        gsap.to($searchInner.nodes, { duration: 0.5, delay: 0.4, opacity: 1, onComplete: () => {
            $searchField.focus();
        }});
    };

    const closeSearch = () => {
        gsap.to($searchInner.nodes, { duration: 0.1, delay: 0, opacity: 0 });
        gsap.to($search.nodes, { duration: 0.3, delay: 0.1, ease: 'power1.out', height: '0%', onComplete: () => {
            focusedElement.focus();
            focusedElement = null;
        } });
    };

    const toggleSearch = () => {
        if (searchOpen) {
            closeSearch();
        } else {
            openSearch();
        }

        searchOpen = !searchOpen;
    };

    const hide = (tween = true) => {
        if (isHidden || menuOpen) {
            return;
        }

        if (!tween) {
            disableAnimations();
        }

        isHidden = true;
        $bar.addClass('is-hidden');
        enableAnimations();
    };

    const show = (tween = true) => {
        if (!isHidden) {
            return;
        }

        if (!tween) {
            disableAnimations();
        }

        isHidden = false;
        $bar.removeClass('is-hidden');
        enableAnimations();
    };

    const onBreakpoint = () => {
        /*
        const wasSmall = isSmall(breakpoint);
        if (wasSmall !== isSmall()) {
            closeMenu(false);
        }

         */
        breakpoint = Viewport.breakpoint.name;
    };

    const onBodyKeyUp = e => {
        const key = e.key || e.keyCode || e.which || null;
        if (['Escape', 27].indexOf(key) > -1) {
            if (menuOpen) {
                toggleMenu();
            } else if (searchOpen) {
                toggleSearch();
            }
        }
    };

    const onScroll = () => {
        const { scrollTop } = Viewport;

        if (Math.abs(scrollTop - currentScrollTop) < 10) {
            return;
        }

        if (scrollTop < hiddenThreshold) {
            show();
        } else {
            const direction = scrollTop > currentScrollTop ? 'down' : 'up';
            if (direction === 'down') {
                hide();
            } else {
                show();
            }
        }

        if (isTransparent) {
            if (scrollTop < scrolledThreshold) {
                $el.removeClass('is-nav-scrolled');
            } else {
                $el.addClass('is-nav-scrolled');
            }
        }

        currentScrollTop = scrollTop;
    };

    const onInnerFocus = e => {
        show();
    };

    const onResize = () => {
        if (!isHidden) {
            return;
        }

        requestAnimationFrame(() => {
            const { scrollTop } = Viewport;
            if (scrollTop <= hiddenThreshold) {
                show();
            }
        });
    };

    const init = () => {
        $menuTriggers.on('click', e => {
            e.preventDefault();
            toggleMenu();
        });

        $searchTriggers.on('click', e => {
            e.preventDefault();
            toggleSearch();
        });

        isTransparent = $body.hasClass('is-nav-transparent');

        $('body').on('keyup', onBodyKeyUp);
        Viewport.on('breakpoint', onBreakpoint);
        Viewport.on('resize', onResize);
        Viewport.on('scroll', onScroll);

        $bar.on('focusin', 'a,button', onInnerFocus);

        if ((window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0) > 0) {
            hide(false);
        }

        onResize();
        $bar.css({ opacity: 1 });
    };

    const destroy = () => {
        $menuTriggers.off('click');
        $searchTriggers.off('click');
        $('body').off('keyup', onBodyKeyUp);
    };

    return {
        init,
        destroy
    };
};
