import $ from '@vaersaagod/tools/Dom';

export default (el, props) => {
    const $el = $(el); 
    const $orderTotalShipping = $el.find('[data-order-total-shipping]'); 
    const $orderTotal = $el.find('[data-order-total]'); 
    
    const $orderAddressFieldset = $el.find('[data-order-address-fieldset]'); 
    const $deliveryAddressFieldset = $el.find('[data-delivery-address-fieldset]'); 
    
    const $orderTypeRadio = $el.find('[data-ordertype-radio]'); 

    const handleTypeChange = () => {
        if ($el.find('[data-ordertype-radio] input:checked').val() === 'gift') {
            $deliveryAddressFieldset.css({ display: 'block' });
        } else {
            $deliveryAddressFieldset.css({ display: 'none' });
        }
    };
    
    const init = () => {
        handleTypeChange();
        
        $orderTypeRadio.on('click', () => {
            handleTypeChange();
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
