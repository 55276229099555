import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

export default (el, props) => {
    const $el = $(el);
    const $form = $el.find('form');
    const $button = $el.find('button[type="submit"]');
    const $infoToggle = $el.find('[data-info-toggle]');
    const $emailField = $el.find('[data-email-field]');
    const $infoPanel = $el.find('[data-info-panel]');

    let infoVisible = false;
    let isRequesting = false;

    const { confirmMessage } = props;

    const success = () => {
        $form.find('[data-email-field]').val(confirmMessage);

        setTimeout(() => {
            $form.find('[data-email-field]').val('');
        }, 3000);
    };

    const showError = result => {
        const email = $emailField.val();

        $form.find('[data-email-field]').val('En feil oppstod!');

        setTimeout(() => {
            $form.find('[data-email-field]').val(email);
        }, 3000);
    };

    const submit = () => {
        if (!isRequesting) {
            const fields = serialize($form.get(0), { hash: false });
            const fieldsData = serialize($form.get(0), { hash: true });

            if (fieldsData.email === undefined || fieldsData.email === '') {
                return;
            }

            isRequesting = true;
            $button.attr('disabled', 'disabled');

            request
                .post(document.location.href)
                .accept('application/json')
                .send(fields)
                .then(res => {
                    const {
                        status,
                        body
                    } = res || {};

                    if (status === 200 && body) {
                        if (body.newsletterSignup !== undefined && body.newsletterSignup.resultat !== undefined && body.newsletterSignup.resultat === '2') {
                            success();
                        } else {
                            showError(body);
                        }
                    } else {
                        showError(null);
                    }
                })
                .catch(error => {
                    showError(null);
                })
                .then(() => {
                    isRequesting = false;
                    $button.attr('disabled', null);
                });
        }
    };

    const init = () => {
        /* $form.on('submit', e => {
            e.preventDefault();
            submit();
        }); */

        $infoToggle.on('click', e => {
            e.preventDefault();

            if (infoVisible) {
                $infoPanel.removeClass('flex')
                    .addClass('hidden');
            } else {
                $infoPanel.removeClass('hidden')
                    .addClass('flex');
            }

            infoVisible = !infoVisible;
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
