import $ from '@vaersaagod/tools/Dom';
import Flickity from 'flickity';

export default el => {
    let flkty = null;

    const init = () => {
        flkty = new Flickity(el, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: true,
            arrowShape: 'M6,50l34.2-34.2v68.3C40.2,84.2,6,50,6,50z M51.8,50L86,15.8v68.3L51.8,50z',
            pageDots: false,
            wrapAround: false,
            freeScroll: true,
            freeScrollFriction: 0.045
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });
    };

    const destroy = () => {
        flkty.destroy();
    };

    return {
        init,
        destroy
    };
};
