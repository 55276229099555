import $ from '@vaersaagod/tools/Dom';

export default el => {
    const button = $(el);
    const target = $(document.getElementById(button.attr('aria-controls')));

    const openText = button.text();
    const closeText = button.data('collapsed-text');

    const toggle = () => {
        const isOpen = button.attr('aria-expanded') === 'true';
        button.attr('aria-expanded', isOpen ? 'false' : 'true');
        button.text(isOpen ? openText : closeText);
        target.toggleClass('collapsed', isOpen);
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };

};
