import $ from '@vaersaagod/tools/Dom';

export default el => {
    const $el = $(el);
    const $input = $el.find('input');
    const $increaseBtn = $el.find('[data-quantity-increase]');
    const $decreaseBtn = $el.find('[data-quantity-decrease]');

    let currentVal = 0;

    const updateCurrentVal = () => {
        currentVal = parseInt($input.val(), 10);
    };

    const increase = () => {
        currentVal += 1;

        if (currentVal > 99) {
            currentVal = 99;
        }

        $input.val(currentVal);
    };

    const decrease = () => {
        currentVal -= 1;

        if (currentVal < 0) {
            currentVal = 0;
        }

        $input.val(currentVal);
    };

    const init = () => {
        updateCurrentVal();

        $input.on('change', e => {
            updateCurrentVal();
        });

        $increaseBtn.on('click', e => {
            increase();
        });

        $decreaseBtn.on('click', e => {
            decrease();
        });
    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };

};
