import $ from '@vaersaagod/tools/Dom';

const getQueryVariable = variable => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return '';
};

const supportsLocalStorage = () => {
    try {
        return 'localStorage' in window && window.localStorage !== null;
    } catch (e) {
        return false;
    }
};

const checkActionNumber = () => {
    if (getQueryVariable('utm_content') !== '') {
        console.log('we have an action number');
        const actionNumber = getQueryVariable('utm_content');

        if (supportsLocalStorage) {
            console.log(`Setting actionNumber to ${actionNumber}`);
            localStorage.setItem('actionNumber', actionNumber);
        }
    }

    if (supportsLocalStorage) {
        const $field = $('input[name="action_number"]');

        if ($field.length > 0) {
            console.log(`We have a field. Action number is ${localStorage.getItem('actionNumber')}`);
            $field.val(localStorage.getItem('actionNumber'));
        } else {
            console.log(`We don't have a field. Action number is ${localStorage.getItem('actionNumber')}`);
        }
    }
};

const getActionNumber = () => localStorage.getItem('actionNumber');

export default {
    checkActionNumber,
    getActionNumber
};
