import $ from '@vaersaagod/tools/Dom';
import agent from '@vaersaagod/tools/request';
import serialize from 'form-serialize';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { Quart, Sine, Quint } from 'gsap/gsap-core';

export default (el, props) => {
    const dom = $(el);
    const counter = dom.find('[data-counter]');
    const progressbar = dom.find('[data-progressbar]').get(0);
    const tooltip = dom.find('[data-tooltip]').get(0);
    const form = dom.find('[data-form]');
    const formWrapper = dom.find('[data-form-wrapper]');
    const receipt = dom.find('[data-receipt]');
    const receiptSuccess = dom.find('[data-success]');
    const receiptDuplicate = dom.find('[data-duplicate]');

    const values = {
        goal: parseInt(props.goal, 10),
        count: 0,
        anim: 0
    };

    const resetValues = () => {
        gsap.killTweensOf([progressbar, tooltip, values]);
        gsap.set(tooltip, {left: 0, y: 20, opacity: 0});
        gsap.set(progressbar, {scaleX: 0});
        counter.text('0');
        values.count = 0;
        values.anim = 0;
    };

    const animateProgress = () => {
        const percent = Math.min(100, (values.count / values.goal) * 100);
        const isUpdate = values.anim !== 0;
        if (isUpdate) {
            gsap.to(tooltip, {duration: 1, left: `${percent}%`, ease: Quart.easeInOut});
        }
        gsap.to(progressbar, {
            duration: 2.5,
            scaleX: `${percent / 100}`,
            ease: Quint.easeInOut,
            onComplete() {
                if (!isUpdate) {
                    gsap.set(tooltip, {left: `${percent}%`, y: 20});
                    gsap.to(tooltip, {duration: 0.25, opacity: 1, ease: Sine.easeOut});
                    gsap.to(tooltip, {duration: 0.4, y: 0, ease: Quint.easeOut});
                }
            }
        });
        gsap.to(values, {
            duration: 2.5,
            anim: values.count,
            ease: Quint.easeInOut,
            onUpdate() {
                counter.text(Math.ceil(values.anim));
            }
        });
    };

    const getSignatures = () => {
        agent
            .get(props.countEndpoint)
            .query({actionId: props.profundoId})
            .end((error, res) => {
                if (error || !res.body) {
                    console.log('error');
                } else {
                    values.count = parseInt(res.body.count, 10);
                    animateProgress();
                }
            });
    };

    const onSubmitForm = e => {
        e.preventDefault();

        agent
            .post(props.registerEndpoint)
            .accept('application/json')
            .send(serialize(form.get(0)))
            .end((error, res) => {
                if (error || !res.body) {
                    // TODO
                } else {
                    console.log(res.body.status);
                    getSignatures();
                    if (res.body.status === 'existed') {
                        receiptDuplicate.removeClass('hidden');
                    } else {
                        receiptSuccess.removeClass('hidden');
                    }
                    gsap.to(formWrapper.get(0), {duration: 0.5, autoAlpha: 0, y: -30, ease: Quart.easeInOut});
                    gsap.set(receipt.get(0), {y: 30, autoAlpha: 0});
                    receipt.removeClass('hidden');
                    gsap.to(receipt.get(0), {delay: 0.65, duration: 0.5, autoAlpha: 1, y: 0, ease: Quart.easeInOut});
                }
            });
    };

    const init = () => {
        form.on('submit', onSubmitForm);
        ScrollTrigger.create({
            trigger: progressbar,
            start: 'top bottom',
            end: 'top top',
            onEnter() {
                getSignatures();
            },
            onEnterBack() {
                getSignatures();
            },
            onLeave() {
                resetValues();
            },
            onLeaveBack() {
                resetValues();
            }
        });
    };

    const destroy = () => {
        form.off('submit', onSubmitForm);
    };

    return {
        init,
        destroy
    };
};
