import $ from '@vaersaagod/tools/Dom';
import ActionNumberHandler from '../lib/ActionNumberHandler';

export default el => {
    const $el = $(el);
    const $iframe = $el.find('[data-form-iframe]');

    let lastHeight = 0;

    const init = () => {
        const src = $iframe.data('src');
        const actionNumber = ActionNumberHandler.getActionNumber();
        $iframe.attr('src', src + (actionNumber && actionNumber !== '' ? `?a=${actionNumber}` : ''));

        window.addEventListener('message', event => {
            const { data } = event;
            const sourceWindow = event.source;

            if (sourceWindow === $iframe.get(0).contentWindow) {
                $iframe.css({ opacity: 1 });

                if (event && data && data.height && lastHeight !== data.height) {
                    $el.height(data.height);
                    lastHeight = data.height;
                }
            }
        }, false);

        $(window)
            .on('message', e => {
                console.log('message!', e);

                const event = e.originalEvent;
                const { data } = event;
                const sourceWindow = e.originalEvent.source;

                if (sourceWindow === $iframe.get(0).contentWindow) {
                    $iframe.css({ opacity: 1 });

                    if (event && data && data.height && lastHeight !== data.height) {
                        $el.height(data.height);
                        lastHeight = data.height;
                    }
                }
            });

    };

    const destroy = () => {
            
    };

    return {
        init,
        destroy
    };
};
