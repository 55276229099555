import $ from '@vaersaagod/tools/Dom';

import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    // BubbleController,
    DoughnutController,
    LineController,
    PieController,
    // PolarAreaController,
    // RadarController,
    // ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    // RadialLinearScale,
    // TimeScale,
    // TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
} from 'chart.js';

import pattern from 'patternomaly';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    // BubbleController,
    DoughnutController,
    LineController,
    PieController,
    // PolarAreaController,
    // RadarController,
    // ScatterController,
    CategoryScale,
    LinearScale,
    // LogarithmicScale,
    // RadialLinearScale,
    // TimeScale,
    // TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
);

export default (el, props) => {
    const $el = $(el);
    const $canvas = $el.find('canvas');

    const {
        type,
        labels,
        data,
        background
    } = props;

    const colorTranslateMap = {
        brightGreen: 'rgb(0, 255, 0)',
        darkerGreen: 'rgb(0, 173, 0)',
        black: 'rgb(0, 0, 0)',
        white: 'rgb(255, 255, 255)',
        lightGray: 'rgb(235, 235, 235)',
        stoneGray: 'rgb(91, 91, 91)'
    };

    const parseFill = (col, pat) => {
        const c = colorTranslateMap[col];

        if (pat !== 'none') {
            return pattern.draw(pat, c, background === 'light' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)');
        }

        return c;
    };

    const parseDataSets = indata => {
        const r = [];

        indata.forEach(set => {
            const dataset = {
                label: set.label,
                data: [],
                backgroundColor: []
            };

            set.data.forEach(d => {
                dataset.data.push(d.value);
                dataset.backgroundColor.push(parseFill(d.color, d.pattern));
            });

            r.push(dataset);
        });

        return r;
    };

    const init = () => {
        Chart.defaults.color = (background === 'light' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)');
        Chart.defaults.borderColor = (background === 'light' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)');
        Chart.defaults.elements.bar.borderWidth = 2;
        Chart.defaults.elements.arc.borderWidth = 2;
        Chart.defaults.elements.arc.borderColor = (background === 'light' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)');
        Chart.defaults.font.size = 16;
        Chart.defaults.font.family = '\'Falster\', sans-serif';
        console.log(background);
        console.log(Chart.defaults.elements.arc.borderColor);

        const datasets = parseDataSets(data);

        const myChart = new Chart($canvas.get(0)
            .getContext('2d'), {
            type,
            data: {
                labels,
                datasets
            },
            options: {
                layout: {
                    padding: 0
                },
                scales: {
                    y: {
                        display: type === 'bar',
                        grid: {
                            borderWidth: 2,
                            borderColor: background === 'light' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)'
                        }
                    },
                    x: {
                        display: type === 'bar',
                        grid: {
                            borderWidth: 2,
                            borderColor: background === 'light' ? 'rgb(0, 0, 0)' : 'rgb(255, 255, 255)'
                        }
                    }
                },
                animation: false,
                plugins: {
                    legend: {
                        display: type !== 'bar',
                        position: 'bottom',
                        align: 'center',
                        labels: {
                            padding: 30,
                            boxWidth: 32,
                            boxHeight: 32
                        }
                    },
                    tooltip: {
                        padding: 10,
                        caretSize: 10,
                        cornerRadius: 0,
                        boxWidth: 24,
                        boxHeight: 24,
                        displayColors: false
                        /*
                        callbacks: {
                            label: context => {
                                var label = context.dataset.label || '';

                                if (label) {
                                    label += ': ';
                                }
                                if (context.dataset.value !== null) {
                                    label += context.dataset.value;
                                    
                                    label += '%';
                                }
                                return label;
                            }
                        }*/
                    }
                }

            }
        });

    };

    const destroy = () => {

    };

    return {
        init,
        destroy
    };
};
