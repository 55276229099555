import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default el => {
    const video = el;
    const dom = $(el);

    let playing = false;
    let loaded = false;

    const onTimeUpdate = () => {
        if (video.currentTime < 0.001) {
            return;
        }
        playing = true;
        video.removeEventListener('timeupdate', onTimeUpdate);
        gsap.to(video, { duration: 0.5, opacity: 1, ease: '' });
    };

    const play = () => {
        if (!loaded) {
            loaded = true;
            dom.attr('src', dom.data('src'));
        } else if (!playing) {
            playing = true;
            video.play();
        }
    };

    const pause = () => {
        if (playing) {
            playing = false;
            video.pause();
        }
    };

    const init = () => {
        video.addEventListener('timeupdate', onTimeUpdate);
        video.instance = {
            play,
            pause
        };
    };

    const destroy = () => {
        video.removeEventListener('timeupdate', onTimeUpdate);
        video.instance = null;
    };

    return {
        init,
        destroy
    };
};
