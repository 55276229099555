import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

export default (el, props) => {
    const $el = $(el); 
    const $trigger = $el.find('[data-video-trigger]');
    const $container = $el.find('[data-video-container]');
    const $text = $el.find('[data-video-text]');

    const { embedCode } = props;
    
    const showVideo = () => {
        const $embedCode = $(embedCode);
        $embedCode.addClass('absolute full');
        $container.append($embedCode).removeClass('hidden').css({ opacity: 0 });
        
        gsap.to($text.nodes, { duration: 0.2, opacity: 0 });
        gsap.to($container.nodes, { duration: 0.5, delay: 0.2, opacity: 1 });
    };
    
    const init = () => {
        $trigger.on('click', e => {
            e.preventDefault();
            showVideo();
        });
    };

    const destroy = () => {
        $trigger.off('click');
    };

    return {
        init,
        destroy
    };
};
