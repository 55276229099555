import $ from '@vaersaagod/tools/Dom';

export default el => {
    const popupLinks = $(el).find('[data-popup]');
    const copyLinks = $(el).find('[data-copy]');

    const openPopup = (url, width = 600, height = 600) => {
        const left = (window.screen.width / 2) - (width / 2);
        const top = (window.screen.height / 2) - (height / 2);
        window.open(url, 'shareWindow', `height=${height},width=${width}, top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,directories=no,status=no`);
    };

    const toClipboard = text => {
        const input = $('<input>');
        $(el).append(input);
        input.val(text);
        input.get(0).select();
        document.execCommand('copy');
        input.remove();
    };

    const init = () => {
        popupLinks.on('click', e => {
            e.preventDefault();
            openPopup(e.target.href);
        });
        copyLinks.on('click', e => {
            e.preventDefault();
            toClipboard(e.target.href);
        });
    };

    const destroy = () => {
        popupLinks.off('click');
        copyLinks.off('click');
    };

    return {
        init,
        destroy
    };
};
