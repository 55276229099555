import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';

export default el => {
    const dom = $(el);
    const node = dom.find('[data-number]');
    const values = {
        current: 0,
        initial: node.text(),
        target: parseFloat(node.data('number'))
    };

    let observer = null;

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            gsap.to(values, {
                duration: 3,
                current: values.target,
                ease: 'power3.inOut',
                onUpdate: () => {
                    node.text(Math.round(values.current));
                }
            });
        } else {
            gsap.killTweensOf(values);
            values.current = 0;
            node.text(values.current);
        }
    };

    const resize = () => {
        // node.text(values.initial).css('width', null);
        // node.width(Math.ceil(node.width() * 1.20));
    };

    const init = () => {
        observer = new IntersectionObserver(onIntersectionChange);
        observer.observe(el);
        // Viewport.on('resize', resize);
        // resize();
    };

    const destroy = () => {};

    return {
        init,
        destroy
    };
};
