import $ from '@vaersaagod/tools/Dom';
import request from '@vaersaagod/tools/request';
import serialize from 'form-serialize';

export default el => {
    const $el = $(el);

    const $resultsTable = $el.find('[data-results-table]');
    const $showMoreBtn = $el.find('[data-results-more-btn]');
    const $resultsInfo = $el.find('[data-results-info]');
    const $searchForm = $el.find('[data-search-form]');
    const $productCategorySelect = $el.find('[data-product-type-select]');
    const $amountRadio = $el.find('[data-amount-radio]');
    const $columnHeaders = $el.find('[data-column-header-trigger]');
    
    const $paginateWrap = $el.find('[data-paginate-wrap]');
    const $noresultsWrap = $el.find('[data-noresults-wrap]');

    let sort = '';

    const updateResultsTable = ($items, $btn, $info, append) => {
        if (!append) {
            $resultsTable.find('[data-result-row]').remove();
        }
        
        if ($items.length === 0 && !append) {
            $resultsTable.css({ display: 'none' });
            $paginateWrap.css({ display: 'none' });
            $noresultsWrap.css({ display: '' });
        } else {
            $resultsTable.css({ display: '' });
            $paginateWrap.css({ display: '' });
            $noresultsWrap.css({ display: 'none' });
        }
        
        $resultsTable.append($items);

        if ($btn.length > 0) {
            $showMoreBtn.css({ display: '' });
            $showMoreBtn.attr('href', $btn.attr('href'));
        } else {
            $showMoreBtn.css({ display: 'none' });
        }

        $resultsInfo.find('.last-num').text($info.find('.last-num').text());
        $resultsInfo.find('.total').text($info.find('.total').text());
    };

    const showMore = () => {
        request
            .get($showMoreBtn.attr('href'))
            .then(res => {
                if (res.statusCode === 200) {
                    const $newData = $(`<div>${res.text}</div>`);
                    const $items = $newData.find('[data-result-row]');
                    const $btn = $newData.find('[data-results-more-btn]');
                    const $info = $newData.find('[data-results-info]');

                    updateResultsTable($items, $btn, $info, true);
                }
            });
    };

    const submitSearch = () => {
        const serializedData = serialize($searchForm.get(0));
        const queryString = `?${serializedData}${sort !== '' ? `&sort=${sort}` : ''}`;
        
        request
            .get($searchForm.data('action-url') + queryString)
            .then(res => {
                if (res.statusCode === 200) {
                    const $newData = $(`<div>${res.text}</div>`);
                    const $items = $newData.find('[data-result-row]');
                    const $btn = $newData.find('[data-results-more-btn]');
                    const $info = $newData.find('[data-results-info]');

                    updateResultsTable($items, $btn, $info, false);
                } 
            });
    };

    const init = () => {
        $productCategorySelect.on('change', () => {
            submitSearch();
        });

        $amountRadio.on('change', () => {
            submitSearch();
        });

        $searchForm.on('submit', e => {
            e.preventDefault();
            submitSearch();
        });

        $showMoreBtn.on('click', e => {
            e.preventDefault();
            showMore();
        });

        $columnHeaders.on('click', e => {
            e.preventDefault();
            sort = $(this).data('sort');
            submitSearch();
        });
    };

    const destroy = () => {
        $productCategorySelect.off('change');
        $amountRadio.off('change');
        $searchForm.off('submit');
        $showMoreBtn.off('click');
        $columnHeaders.off('click');
    };

    return {
        init,
        destroy
    };
};
