import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import {Back, Cubic, Sine} from "gsap/gsap-core";

export default el => {
    const dom = $(el);
    const dialogs = dom.find('[data-dialog]');

    let viewportHeight = 0;
    let scrollTop = 0;
    let active = false;

    const closeDialog = e => {
        if (e.type === 'focusout' && e.triggerTarget.contains(e.relatedTarget)) {
            return false;
        }
        if (e.type === 'mouseleave' && e.triggerTarget.contains(document.activeElement)) {
            return false;
        }
        const button = $(e.triggerTarget).find('[aria-expanded]');
        if (button) {
            button.attr('aria-expanded', false);
        }
    };

    const openDialog = e => {
        const button = $(e.triggerTarget).find('[aria-expanded]');
        if (button) {
            button.attr('aria-expanded', true);
        }
    };

    const onScroll = () => {
        scrollTop = Viewport.scrollTop;
        if (!active && scrollTop >= viewportHeight) {
            active = true;
            gsap.set(el, { display: 'flex', y: 100, opacity: 0 });
            gsap.to(el, { duration: 0.5, y: 0, ease: Cubic.easeOut });
            gsap.to(el, { duration: 0.35, opacity: 1, ease: Sine.easeOut });
        } else if (active && scrollTop < viewportHeight) {
            active = false;
            gsap.to(el, { duration: 0.5, y: 100, opacity: 0, ease: Cubic.easeIn, onComplete: () => {
                gsap.set(el, { display: 'none' });
            }});
        }
    };

    const onResize = () => {
        viewportHeight = Viewport.height;
        onScroll();
    };

    const init = () => {
        dialogs.on('mouseenter', openDialog);
        dialogs.on('mouseleave', closeDialog);
        dialogs.on('focusin', openDialog);
        dialogs.on('focusout', closeDialog);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        onResize();
    };

    const destroy = () => {
        dialogs.off('mouseenter', openDialog);
        dialogs.off('mouseleave', closeDialog);
        dialogs.off('focusin', openDialog);
        dialogs.off('focusout', closeDialog);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
